// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

// FullCalendar
import moment from 'moment';
window.moment = moment;
 
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

//------------------//
// Toastr
//------------------//
import toastr from 'toastr';

// Optional configuration
toastr.options = {
    closeButton: true, // Show close button
    debug: false,
    newestOnTop: false,
    progressBar: true, // Show progress bar
    positionClass: "toast-top-right", // Position of toast
    preventDuplicates: true,
    onclick: null,
    showDuration: "300", // Time (in ms) the toast will take to show
    hideDuration: "1000", // Time (in ms) the toast will take to hide
    timeOut: "6000", // Time (in ms) the toast will be on screen
    extendedTimeOut: "1000", // Time (in ms) the toast will remain on the screen after a user hovers over it
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn", // Animation type on show
    hideMethod: "fadeOut" // Animation type on hide
  };
  
  window.toastr = toastr;

// Start Rails, Turbolinks and ActiveStorage
Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;
require("trix")
require("@rails/actiontext")